import '@chenfengyuan/datepicker'
import { tns } from 'tiny-slider/src/tiny-slider'

import Foundation from 'foundation-sites'

// Mobile Menu
$(document).on('click', '.mobile-menu a', function() {
  if ($(this).attr('href') != "javascript:void(0)") {
    $('.mobile-menu').removeClass('opened')
  } else {
    // Toggle Dropdown
    let $li = $(this).parent()
    if ($li.hasClass('expanded')) {
      $li.removeClass('expanded')
    } else {
      $li.addClass('expanded')
    }
  }
})

$(document).on('click', '.mobile-menu__trigger', function() {
  $('.mobile-menu').toggleClass('opened')
  console.log("Mm")
})

$(document).on('change', '#division-dropdown', function() {
  window.location = $(this).val()
})

document.addEventListener("turbolinks:load", function() {
  //$('.knockout-match').tipr({
  //});

  // Foundation.reflow(this);
  // const seasonDrop = new Foundation.Dropdown($('#season-dropdown'), { autoClose: false, closeOnClick: true })

  // Mobile Menu
  if ($('.mobile-menu').html().trim() == "") {
    $('ul.main-nav').clone().appendTo('.mobile-menu')
    $('<li><a href="/contact">Contact the League</a></li>').appendTo($('.mobile-menu > ul'))
    $('.mobile-menu ul').removeClass('main-nav')

    if ($('nav.captains').length) {
      $('<li><a href="/players/logout">Logout</a></li>').appendTo($('.mobile-menu > ul'))

      $('nav.captains').clone().appendTo('.mobile-menu')
      $('.mobile-menu nav.captains').addClass('mobile-captains')
      $('.mobile-menu nav.captains').removeClass('captains')
    } else {
      $('<li><a href="/players/login">Captain\'s Login</a></li>').appendTo($('.mobile-menu > ul'))
    }

    $('.mobile-menu .main-nav__dropdown').each(function() {
      $(this).removeClass('main-nav__dropdown')
      $(this).addClass('mobile-menu__dropdown')
/*
      $(this).parent().find('> a').click(function(e) {
        e.preventDefault()
        e.stopPropagation()

        let $li = $(this).parent()
        if ($li.hasClass('expanded')) {
          $li.removeClass('expanded')
        } else {
          $li.addClass('expanded')
        }
      })*/
    })
  }

  


  // Misc
/*
  $('#hole_in_one_happened_at').datepicker({
    format: 'dd/mm/yyyy'
  });*/

  $('#season-dropdown').on('change', function() {
    window.location = `/divisions/${$(this).val()}/1`
  });

  if ($('.home-slider').length) {
    tns({
      container: '.home-slider',
      mode: 'gallery',
      nav: false,
      autoplay: true,
      startIndex: Math.floor(Math.random() * $('.home-slider__slide').length) - 1,
      autoplayButtonOutput: false,
      controlsContainer: $('.home-slider__controls')[0]
    })
  }

  if ($('.mini-results').length) {
    tns({
      container: '.mini-results',
      items: 1,
      nav: false,
      autoplay: true,
      autoplayButtonOutput: false,
      startIndex: Math.floor(Math.random() * $('.mini-results__slide').length) - 1,
      controlsContainer: $('.mini-results__controls')[0]
    })
  }

  // CMS Responsive Tables
  $('article table').each(function() {
    $(this).wrap(`<div class="responsive-table-wrap responsive-table-wrap--fix-1 cms-table"></table>`)
  })
});