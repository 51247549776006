import { Controller } from "stimulus"
import * as $ from 'jquery'

export default class extends Controller {
  static targets = ["joinFields", "toggleCheckbox"]

  connect() {
    this.toggleJoin()

    $(this.toggleCheckboxTarget).append(`<input type="hidden" name="contact_form[_hp]" value="valid" />`)
  }

  toggleJoin() {
    $(this.joinFieldsTarget).toggle($(this.toggleCheckboxTarget).is(':checked'))
  }
}