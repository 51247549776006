import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["name", "team"]

  connect() {
  }

  create() {
    if (this.nameTarget.value.length < 2) {
      alert("Please enter a valid player name")
      return
    }

    const self = this

    $.ajax({
      url: `/captains/players.json?team=${this.teamTarget.value}`,
      method: "POST",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      data: {
        name: this.nameTarget.value
      }
    }).done(function(resp) {
      self.updateAvailablePlayers(self.teamTarget.value, resp)
      self.closeModal()
    }).fail(function(jqXHR, status) {
      alert(`Failed to create player: ${jqXHR.responseJSON.error}`)
    })
  }

  closeModal() {
    $(this.element).remove()
    $('.reveal-overlay').remove()
    $('html').removeClass('is-reveal-open')
  }

  updateAvailablePlayers(team, players) {
    $('.league-match select').each(function() {
      if ($(this).attr('data-team') == team) {
        const selected = $(this).val()

        $(this).html('')
        $(this).append('<option value=""></option>')

        players.forEach(p => {
          $(this).append(`<option value="${p.id}">${p.name}</option>`)
        })

        $(this).val(selected)
      }
    })
  }
}