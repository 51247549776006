import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["fixture", "filterSelect"]

  connect() {
  }

  filter() {
    const query = this.filterSelectTarget.options[this.filterSelectTarget.selectedIndex].text

    if (query == "All Fixtures") {
      this.fixtureTargets.forEach((el, i) => {
        el.classList.remove("fixture--search-hidden")
      })
    } else {
      this.fixtureTargets.forEach((el, i) => {
        const match = el.innerText.includes(query)
        el.classList.toggle("fixture--search-hidden", !match)
      })
    }
  }

  focusTeam(e) {
    const team = e.currentTarget.getAttribute("data-team")

    if (team) {
      $(this.filterSelectTarget).val(team)
      this.filter()

      $('html, body').animate({
        scrollTop: $('.table-fixtures').offset().top,
      }, 1000, 'linear')
    }
  }

  viewFixture(event) {
    window.location = event.currentTarget.attributes['data-fixture-path'].nodeValue
  }
}
