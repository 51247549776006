import { Controller } from "stimulus"
import * as $ from 'jquery'

export default class extends Controller {
  static targets = ["container", "toggleBtn"]

  connect() {
    this.singleView = false

    if ($(window).width() < 500) {
      this.singleView = true
    }

    this.updateView()
  }

  toggleView() {
    this.singleView = !this.singleView
    this.updateView()
  }

  updateView() {
    $(this.containerTarget).toggleClass('knockout--single-round', this.singleView)

    if (this.singleView) {
      $(this.toggleBtnTarget).text("Show All Rounds")
    } else {
      $(this.toggleBtnTarget).text("Show Current Round")

      // Check current round is in view

      setTimeout(() => {
        const $container = $('.knockout-container')
        const $cr = $('.knockout-round--current')

        if ($cr.length) {
          const offset = $cr.position().left - $container.scrollLeft()
          if (offset > window.innerWidth) {
            $container.animate({ scrollLeft: offset }, 1000)
          }
        }
      }, 300)
    }
  }
}