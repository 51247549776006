import { Controller } from "stimulus"

import * as $ from 'jquery'
import '@chenfengyuan/datepicker'

export default class extends Controller {
  static targets = ["results"]

  connect() {
    $('#knockout_match_starts_at_date').datepicker({
      format: 'dd/mm/yyyy'
    });
  }

  enterResults(e) {
    // Remove sending button
    $(e.target).parent().remove()
    
    $(this.resultsTarget).find('.cell').show()
    $(this.resultsTarget).find('.grid-x').show()
  }
}