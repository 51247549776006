import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["map"]

  connect() {
    if (window.clubLocations) {
      this.clubData = window.clubLocations
    } else {
      this.clubData = []
    }

    this.setupGoogleMap()
  }

  showClub(e) {
    this.clubData.forEach(club => {
      if (club.title == e.target.textContent) {
        this.map.setZoom(14)
        this.map.panTo(club.marker.position)
      }
    })    
  }

  setupGoogleMap() {
    const self = this

    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 9,
      center: new google.maps.LatLng(51.883612, -1.483670),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    })

    const infowindow = new google.maps.InfoWindow()

    this.markers = []
    window.clubLocations.forEach((data, i) => {
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(parseFloat(data.lat), parseFloat(data.lng)),
        map: this.map
      })

      self.markers.push(marker)
      data.marker = marker

      google.maps.event.addListener(marker, 'click', (function(marker, i) {
        return function() {
          infowindow.setContent(data.title);
          infowindow.open(this.map, marker);
        }
      })(marker, i));
    })
  }
}