import { Controller } from "stimulus"

import * as $ from 'jquery'
import '@chenfengyuan/datepicker'

export default class extends Controller {
  static targets = ["venue", "results", "homeScore", "awayScore", "match"]

  connect() {
    if (this.hasHomeScoreTarget && this.hasAwayScoreTarget) {
      this.updateResult()
    }

    $('#league_fixture_starts_at_date').datepicker({
      format: 'dd/mm/yyyy'
    });
  }

  populateVenue(e) {
    const select = e.target
    const venue = select.options[select.selectedIndex].getAttribute('data-venue')

    if (venue) {
      this.venueTarget.value = venue
    }
  }

  enterResults(e) {
    const fields = e.target.getAttribute('data-fields')
    $(fields).insertAfter($(this.resultsTarget).find('.grid-x').first())

    $(e.target).parent().remove()
    $(this.resultsTarget).find('.cell').show()
    $(this.resultsTarget).find('.grid-x').show()

    this.updateResult()
  }

  updateResult(e) {
    // Confirm the corresponding checkbox isn't checked
    if (e) {
      const $target = $(e.target)
      if ($target.attr('type') == "checkbox") {
        if ($target.attr('name').endsWith('[home_win]')) {
          const $matching = this.awayWinInput$($target.parent().parent())
          if ($matching.is(':checked')) {
            $matching.prop('checked', false)
          }
        } else {
          const $matching = this.homeWinInput$($target.parent().parent())
          if ($matching.is(':checked')) {
            $matching.prop('checked', false)
          }
        }
      }
    }
    
    let homePoints = 0
    let awayPoints = 0

    this.matchTargets.forEach(match => {
      const homeWin = this.homeWinInput$(match).is(':checked')
      const awayWin = this.awayWinInput$(match).is(':checked')

      if (homeWin) {
        homePoints += 1
      } else if (awayWin) {
        awayPoints += 1
      } else {
        homePoints += 0.5
        awayPoints += 0.5
      }
    })

    this.homeScoreTarget.textContent = this.formatScore(homePoints)
    this.awayScoreTarget.textContent = this.formatScore(awayPoints)
  }

  // Internal
  //

  formatScore(points) {
    if ((points % 1) != 0) {
      if (Math.floor(points) > 0) {
        return `${Math.floor(points)}½`
      } else {
        return `½`
      }
    } else {
      return points
    }
  }

  homeWinInput$(match) {
    let input = null

    $(match).find('input[type=checkbox]').each(function() {
      if ($(this).attr('name').endsWith('[home_win]')) {
        input = $(this)
      }
    })

    return input
  }

  awayWinInput$(match) {
    let input = null

    $(match).find('input[type=checkbox]').each(function() {
      if ($(this).attr('name').endsWith('[away_win]')) {
        input = $(this)
      }
    })

    return input
  }
}