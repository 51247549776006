import { Controller } from "stimulus"

import $ from 'jquery'
import Cookies from 'js-cookie'

import dt from 'datatables.net-zf'
import 'datatables.net-fixedcolumns-zf'
import 'datatables.net-fixedheader-zf'

window.$.DataTable = dt

export default class extends Controller {
  static targets = ["seasons", "divisionBar", "divisionDropdown", "table", "hideArchived"]

  connect() {
    this.defaultParams = {}

    if (this.data.get("team")) {
      this.defaultParams.team = this.data.get("team")
    }

    if (this.data.get("player")) {
      this.defaultParams.player = this.data.get("player")
    }

    if (this.data.get("scope-season")) {
      this.defaultParams.scope_season = this.data.get("scope-season")
    }

    if (this.hasHideArchivedTarget) {
      this.defaultParams.archived = false
    }

    if (this.hasSeasonsTarget) {
      let initialDivision = 1

      if (this.data.get('initial-division')) {
        initialDivision = this.data.get('initial-division')
      }

      this.changeSeason(initialDivision)
    }

    this.table = this.initDataTable()
  }

  changeSeason(division = 1) {
    const selection = this.selectedSeason
    this.season = selection.season

    if (this.season == "All Time") {
      this.division = division
      this.setupDivisionOptions(6)
    } else {
      this.division = division
      this.setupDivisionOptions(selection.divisions)
    }

    this.reloadDataTable()

    Cookies.set('division', JSON.stringify({
      year: this.season,
      number: this.division
    }))
  }

  changeDivision(division) {
    this.division = division
    this.reloadDataTable()

    Cookies.set('division', JSON.stringify({
      year: this.season,
      number: this.division
    }))
  }

  changeDivisionBar(e) {
    const $sender = $(e.target)
    this.changeDivision($sender.data('division'))

    $(this.divisionBarTarget).find('a').removeClass('active')
    $sender.addClass('active')
  }

  changeDivisionDropdown(e) {
    this.changeDivision($(e.target).val())
  }

  changeSeasonDropdown(e) {
    this.changeSeason(1)
  }

  toggleArchived(e) {
    if (e.target.checked) {
      this.defaultParams.archived = false
    } else {
      this.defaultParams.archived = true
    }

    this.reloadDataTable()
  }

  setupDivisionOptions(count) {
    if (!this.hasDivisionBarTarget) {
      return
    }

    const $bar = $(this.divisionBarTarget)
    const $select = $(this.divisionDropdownTarget)
    $bar.html('')
    $select.html('')

    if (count > 0) {
      $bar.append(`<li><a href="javascript:void(0)" data-action="click->players-table#changeDivisionBar" data-division="">All <span class="hide-for-medium-only">Divisions</span></a></li>`)

      $select.append(`<option value="">All Divisions</option>`)

      for (var i = 1; i <= count; i++) {
        let classes = ""
        let options = ""

        if (this.division == i) {
          classes = `class="active"`
          options = `selected`
        }

        $bar.append(`<li><a href="javascript:void(0)" data-action="click->players-table#changeDivisionBar" ${classes} data-division="${i}">Division ${i}</a></li>`)

        $select.append(`<option value="${i}" ${options}>Division ${i}</option>`)
      }

      $bar.show()
      $select.show()
    } else {
      $bar.hide()
      $select.hide()
    }
  }

  get selectedSeason() {
    const selected = this.seasonsTarget.options[this.seasonsTarget.selectedIndex]
    const divisions = parseInt(selected.getAttribute("data-divisions"))

    return { season: selected.text, divisions: divisions }
  }

  reloadDataTable() {
    if (this.table) {
      console.log(this)
      this.table.clear()
      this.table.ajax.reload()
    }
  }

  initDataTable() {
    const selectPath = this.data.get("select-path")
    const self = this
    const responsive = $(window).width() < 680

    let defaultOrderCol = 9
    if (this.defaultParams.scope_season) {
      defaultOrderCol = 0
    }

    const table = $(this.tableTarget).DataTable({
      ajax: {
        url: "/players.json",
        dataSrc: "",
        data: function(d) {
          Object.keys(self.defaultParams).forEach(key => {
            d[key] = self.defaultParams[key]
          })

          if (self.season && self.season.length == 4) {
            d.season = self.season
          }

          if (self.hasDivisionBarTarget && self.division) {
            d.division = self.division
          }
        }
      },
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="spinner"></div>'
      }, 
      order: [[defaultOrderCol, "desc"]],
      pageLength: 50,
      responsive: true,
      autoWidth: false,
      scrollX: responsive,
      fixedColumns: responsive,
      columns: [
        { "data": "name" },
        { "data": "team" },
        { "data": "played" },
        { "data": "home_wins" },
        { "data": "home_draws" },
        { "data": "home_losses" },
        { "data": "away_wins" },
        { "data": "away_draws" },
        { "data": "away_losses" },
        { "data": "points" },
        /*{ "data": null, "render": function(data, type, row) {
          return Math.ceil((data["points"] / (data["played"] * 3)) * 100)
        }},*/
        { "data": "points_for" },
        { "data": "points_against" },
        { "data": null, "render": function(data, type, row) {
          return (data["points_for"] - data["points_against"])
        }}
      ]
    })

    table.on('preXhr.dt', function(e, settings, data){
      $(this).dataTable().api().clear()
      settings.iDraw = 0; //set to 0, which means "initial draw" which with a clear table will show "loading..." again.
      $(this).dataTable().api().draw()
    })

    $('.table-players').find('tbody').on('click', 'tr', function() {
      const player = table.row(this).data()

      let hash = ""
      if (self.defaultParams.scope_season) {
        let match = player.name.match(/(\()(.*?)(\))/)
        if (match != null && match.length >= 3) {
          hash = "#" + match[2] // Match 1 = (, 2 = Year, 3 = )
        }
      }

      window.location = `${selectPath.replace(":id", player.slug)}${hash}`
    })

    return table
  }
}
