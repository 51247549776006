import { Controller } from "stimulus"
import Chart from "chart.js"

export default class extends Controller {
  static targets = ["homeRecord", "awayRecord"]

  connect() {
    const homeRecord = this.homeRecordTarget.attributes['data-record'].nodeValue
    if (homeRecord.indexOf(',') > 0) {
      this.initMiniChart(this.homeRecordTarget, homeRecord.split(","))
    }

    const awayRecord = this.awayRecordTarget.attributes['data-record'].nodeValue
    if (awayRecord.indexOf(',') > 0) {
      this.initMiniChart(this.awayRecordTarget, awayRecord.split(","))
    }
  }

  initMiniChart(el, record) {
    return new Chart(el, {
      type: 'bar',
      responsive: true,
      maintainAspectRatio: false,
      data: {
        labels: ['Wins', 'Draws', 'Losses'],
        datasets: [{
          label: ' ',
          data: record,
          backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 99, 132, 0.2)'
          ],
          borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            display: false
          }]
        }
      }
    });
  }
}